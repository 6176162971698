<template>
  <div class="dashboard" :class="[$mq]">
    <div class="boxes">
      <div
        v-for="(item, size, index) in config.configWidget"
        :key="`${size}-${index}`"
        :class="{
          'directAccess': size === 'small',
          'products-state small-widget': size === 'medium',
          'products-state': size === 'big',
        }">

        <template v-for="widget in item">
          <DirectAccess
            v-if="size == 'small'"
            :details="widget" />

          <SmallWidget
            v-if="size == 'medium'"
            :details="widget" />

          <WidgetBig
            v-if="size == 'big'"
            :details="widget" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DirectAccess from '@/components/domain/dashboard/DirectAccess.vue'
import WidgetBig from '@/components/domain/dashboard/WidgetBig.vue'
import SmallWidget from '@/components/domain/dashboard/SmallWidget.vue'
import { mapState } from 'vuex'
import MenuTools from '../components/layout/MenuTools.vue'
import MenuTop from '../components/layout/MenuTop.vue'

export default {
    name: 'HomeWidgets',
    components: {
        WidgetBig,
        DirectAccess,
        SmallWidget,
        MenuTools,
        MenuTop
    },
    data() {
        return {
            isOnline: true
        }
    },
    computed: {
        ...mapState('login', ['config']),
        pendingProducts() {
            return this.$store.getters['timer/getProductsPendings']
        },

        timerproduct() {
            return this.$store.getters['timerproduct/getHomeWidgetData']
        }
    },
    methods: {
        showingTools() {
            return this.$store.state['menu_tools_is_visible']
        },
        count(param) {
            let store = this.$store.getters['timerproduct/getHomeWidgetData']

            return store[param]
        },
        getLable(position) {
            let lable = this.config.configWidget[position].id

            return lable
        },
        dynamicUrl(position) {
            return this.config.configWidget[position].params.find(item => item.type === 'link').value
        }
    },
    created() {},
    mounted() {},
    destroyed() {}
}
</script>

<style lang="scss">
.zsiq_floatmain {
    display: none !important;
}

.dashboard {
    @include display-flex();
    @include align-items(center);
    width: 100%;
    overflow: auto;

    .boxes {
        @include display-flex();
        @include justify-content(flex-start);
        @include align-content(flex-start);
        @include flex-wrap(wrap);
        flex-direction: column;
        padding: 20px 8px 60px 8px;
        width: 100%;

        .directAccess {
          @include display-flex();

          .box {
            height: 60px;

            img {
              width: 25px;
            }

            .label {
              @include font-size(17);
            }
          }
        }

        .box {
            @include display-flex();
            @include justify-content(center);
            @include align-items();
            @include border-radius(5px);
            @include interaction();
            background-color: #fff;
            // padding: 14px 20px;
            width: 300px;
            margin: 10px 10px 10px 20px;
            gap: 8px;

            .label {
                width: 156px;
                @include display-flex();
                @include justify-content(center);
                @include align-items(center);
                @include font-size(l);
                font-family: $text-bold;
                color: $neutro-s80;
                padding-left: 20px;
                line-height: 30px;
            }

            img {
                width: 35px;
                height: 35px;
            }

            .count-active {
                display: flex;
                @include align-items(center);

                label {
                    font-size: 29px;
                    margin: 0px 0px;
                    color: #157d82;
                    font-weight: 600;
                }
            }
            &.helpcenter {
                .icon {
                    @include background($image: img('book.svg'), $position: center center, $size: 38px);
                }
            }
        }

        .card {
            @include display-flex();
            @include justify-content(center);
            @include align-items();
            @include border-radius(5px);
            @include interaction();
            flex-direction: column;
            background-color: #fff;
            width: 740px;
            margin: 20px;

            .title-card {
                padding: 15px 0px;
                background-color: #e2e2e2;
                position: relative;
                width: 100%;
                text-align: center;
                font-weight: 600;
            }

            .container {
                width: 100%;
                text-align: center;
                padding: 90px 0px;

                label {
                    text-align: center;
                    font-weight: 600;
                }
            }

            button {
                background-color: #1ba844;
                padding: 7px;
                border-radius: 6px;
                position: absolute;
                right: 14px;
                bottom: 8px;
            }
        }

        .products-state {
            display: flex;
            width: 100%;

            .timer-container {
                margin: 11px 20px;
                width: 50%;
                min-width: 0px;
            }
        }

        .small-widget .box {
          height: 117px;
        }
    }
}

@media (max-width: 900px) {
    .dashboard {
        height: unset;
        .boxes {
            .box {
                .label {
                    @include font-size(m);
                }
            }

            .directAccess {
                flex-direction: column;
                padding: 10px;
                gap: 30px;

                .box {
                  width: 100%;
                  margin: 0px;
                }
            }

            .small-widget {
              margin: 9px 0px;
              font-family: $title-bold;
            }

            .products-state {
                flex-direction: column;
                padding: 10px;
                gap: 30px;

                .box {
                  width: 100%;
                  margin: 0px;
                }

                .timer-container {
                  width: 100%;
                  margin: 0px;
                }
            }

            .count {
              font-size: 36px;
            }

            .details-products {
              gap: 20px;

              label span:first-child {
                font-size: 61px;
              }

              label span:last-child {
                font-size: 17px;
              }
            }
        }
    }
}
</style>
