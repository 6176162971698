<template >
  <div v-if="existAlert" class="card-products alerts">
    <div class="container-trash">
        <div class="trash">
            <img src="img/trash.svg" alt="trash" width="79%" />
        </div>
        <div class="details-products">
            <label>
                <span class="count-discard">{{ timerproduct['to_delete'] }}</span>
                <br />
                <span>{{ $t(`widget.product_to_be_discarded`).toUpperCase() }}</span>
            </label>
        </div>
    </div>
    <div class="container-trash">
        <div class="clock">
            <img src="img/clock-circular-outline.svg" alt="clock" width="70%" />
        </div>
        <div class="details-products">
            <label>
                <span class="count-timer">{{ timerproduct['without_timer'] }}</span>
                <br />
                <span>{{ $t(`widget.without_alerts`) }}</span>
            </label>
        </div>
    </div>
  </div>
  <div v-else class="card-products">
    <img src="img/andy-happy.svg" alt="andy-happy" width="20%" />
    <label class="without-alerts">{{ $t(`widget.without_alerts`).toUpperCase() }}</label>
  </div>
</template>

<script>
export default {
    name: 'CardProductTimers',
    props: {
        details: {
            type: Object,
            required: true
        }
    },
    computed: {
        timerproduct() {
            return this.$store.getters['timerproduct/getHomeWidgetData']
        },
        existAlert() {
          return this.timerproduct['to_delete'] > 0 || this.timerproduct['without_timer'] > 0
        }
    },
    methods: {
        count(param) {
            let store = this.$store.getters['timerproduct/getHomeWidgetData']

            return store[param]
        }
    }
}
</script>

<style lang="scss" scoped>
.container-list {
    padding: 22px 15px;
    height: calc(100% - 60px);
}

.timer-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.display-center {
    justify-content: center;
    align-items: center;
}

.container-trash {
    display: flex;
    height: 47%;
}

.trash {
    background-color: #bd1f1e;
    padding: 0px 12px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.clock {
    background-color: #e2e2e2;
    padding: 0px 7.4px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.details-products {
    margin-left: 20px;
}

.details-products label span:first-child {
    font-size: 65px;
    font-family: $title-bold;
}

.details-products label span:last-child {
    color: #757575;
    font-size: 21px;
    font-family: $text-light;
}

.details-products label {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}
.count-discard {
    color: #bd1f1e;
}

.count-timer {
    color: #757575;
}

.without-alerts {
    font-size: 18px;
    font-weight: 600;
    color: #2e2d2d;
}

.card-products {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.alerts {
  gap: 20px;
  padding: 18px 12px;
  align-items: unset;
}
</style>
