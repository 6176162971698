<template>
  <div v-if="!isOffline" @click="$router.push(link)" class="box">
    <img src="/img/box-arrow.svg" alt="box-arrow" />
    <div class="label">
      {{ $t(`widget.${details.id}`).toUpperCase() }}
    </div>
  </div>
  <div v-else class="box">
    <img src="/img/offline/no_wifi.svg" />
  </div>
</template>

<script>
export default {
    name: 'DirectAccessComponent',
    props: {
        details: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isOnline: true
        }
    },
    computed: {
      isOffline() {
        return !fnCheckConnection()
      },
      link() {
        return this.details.params.find(item => item.type === 'link').value
      }
    }
}
</script>

<style scoped lang="scss">
.box {
    width: 320px;
    height: 80px;
}
</style>
